<template>
  <div class="dashboard" style="width:100%;">
    <div class="dashboard__container" style="width:100%; padding:0; max-width:100%;">
      <Loader v-if="!nYUsers || nYUsers.length == 0" />
      <div class="dashboard__container--header flex justify-space-between align-end" style="width:100%; padding:0 2rem; max-width:100%;">
        <div class="flex align-start flex-column">
          <div class="nav__logo">
            J<em>u</em>mp
          </div>
          <h4>NY Tracker Pipeline</h4>
          <div class="mt-0 mb-0">
            Total Applications: <span v-if="totalApplications">{{totalApplications}}</span>
          </div>
          <div class="mt-0 mb-0">
            Total Pending: <span v-if="totalPending">{{totalPending.length}}</span>
          </div>
          <div class="mt-0 mb-0">
            Total Passed: <span v-if="totalPassed">{{totalPassed.length}}</span>
          </div>
          <div class="mt-0 mb-3">
            Total Failed: <span v-if="totalFailed">{{totalFailed.length}}</span>
          </div>
        </div>
      </div>
      <div class="dashboard__container" style="width:100%; padding:0; max-width:100%;">
        <div class="dashboard__container--body"  v-if="nYUsers && nYUsers.length >= 1" >
          <TrackerTable classification="pipeline" :showAccurate="true" :showDrug="true" :showBack="true" :pipeline="nYUsers" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .vgt-responsive {
    border-radius: 0 !important;
}
.dashboard {
  /* background: rgb(20 25 32 / 93%) !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 6rem); */
  background: white;
  padding: 0;
}
.fa-2x {
  font-size: 2rem;
}
h4 {
  opacity: .62;
}
h5 {
  font-size: 1.6rem;
  opacity: 1;
}
input {
  font-size: 1.4rem;
  background: #eee;
  border: none;
}
/* .btn__flat {
  color: white;
} */
.dashboard__container--header {
  align-items: flex-start;
}

.cancelled h5 {
    color: red !important;
  }
/*   .dashboard__container {
    max-width: 90%;
  } */
</style>




<script>
import { mapState } from 'vuex'
import TrackerTable from '@/components/TrackerTableNY.vue'
import * as moment from 'moment'
import router from '@/router'

export default {
  name: 'NYTracker',
  created() {
    this.$store.dispatch('getNYUsers')
  },
  components: {
    TrackerTable
  },
  computed: {
    ...mapState(['currentUser', 'nYUsers']),
    totalApplications() {
      return this.nYUsers.length
    },
    totalPending() {
      return this.nYUsers.filter(user => (
        user &&
        user.checkInvitations &&
        user.checkInvitations[0] &&
        user.checkInvitations[0].payload &&
        user.checkInvitations[0].payload.result &&
        user.checkInvitations[0].payload.result.invitationID && 
        !user.backgroundCheckResponse
      ));
    },
    totalPassed() {
      return this.nYUsers.filter(user => (
        user &&
        user.backgroundCheckResponse &&
        user.backgroundCheckResponse == 'Passed'
      ));
    },
    underReview() {
      return this.nYUsers.filter(user => (
        user &&
        user.checkOrderResults &&
        user.checkOrderResults[0] &&
        user.checkOrderResults[0].payload && 
        !user.backgroundCheckResponse
      ));
    },
    totalFailed() {
      return this.nYUsers.filter(user => (
        user &&
        user.backgroundCheckResponse &&
        user.backgroundCheckResponse == 'Failed Do Not Hire'
      ));


    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearNYUsers");
  }
}
</script>